/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.txtCenter {
  text-align: center;
}

ion-toast.custom-toast {
  --background: #f4f4fa;
  // --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #fff;
  font-weight: bold;
  --background: #55b559;
}
ion-toast.custom-warn-toast {
  --background: #f19d36;
  font-weight: bold;
}

// ion-toast.custom-toast::part(message) {
// // font-style: italic;
// }

// ion-toast.custom-toast::part(button) {
// border-left: 1px solid #d2d2d2;
// color: #030207;
// font-size: 15px;
// }

.height100px {
  height: 100px;
}

.countsModal {
  --max-height: 200px;
  --width: auto;
}

.clrBlue {
  color: #044db8;
}
.pointer {
  cursor: pointer !important;
}
.no-text-decoration {
  text-decoration: none !important;
}

ion-alert.count-alert {
  --backdrop-opacity: 0.7;
}

.count-alert .alert-button-group {
  padding: 8px;
}

button.alert-button.count-button-confirm {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}
button.alert-button.count-button-cancel {
  color: var(--ion-color-danger);
}

.md button.alert-button.count-button-confirm {
  border-radius: 4px;
}

.ios .count-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.ios button.alert-button.count-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.ios button.alert-button.count-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}